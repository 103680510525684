<template>
  <div>
    <p>I got…</p>
    <p><a href="https://www.linkedin.com/in/marcrufeis/" target="_blank" rel="noopener noreferrer"><i class="fab fa-linkedin"></i> LinkedIn</a></p>
    <p><a href="https://github.com/craftycram/" target="_blank" rel="noopener noreferrer"><i class="fab fa-github"></i> GitHub</a></p>
    <p><a href="https://discord.gg/fZ4jyPj" target="_blank" rel="noopener noreferrer"><i class="fab fa-discord"></i> Discord</a></p>
    <p><a href="https://twitch.tv/craftycram/" target="_blank" rel="noopener noreferrer"><i class="fab fa-twitch"></i> Twitch</a></p>
    <p><a href="https://www.instagram.com/marcrufeis/" target="_blank" rel="noopener noreferrer"><i class="fab fa-instagram"></i> Instagram</a></p>
    <p><a href="https://twitter.com/craftycram/" target="_blank" rel="noopener noreferrer"><i class="fab fa-twitter"></i> Twitter</a></p>
    <p><router-link id="back" title="◄ go back" to="/">◄ back</router-link></p>
  </div>
</template>

<script>
  export default {
    name: 'Socials',
  }
</script>

<style scoped>
p {
  color: #3b3b3b;
  font-size: 300%;
  margin: 0;
}
</style>