var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',[_vm._v("I got…")]),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('p',[_c('router-link',{attrs:{"id":"back","title":"◄ go back","to":"/"}},[_vm._v("◄ back")])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('a',{attrs:{"href":"https://www.linkedin.com/in/marcrufeis/","target":"_blank","rel":"noopener noreferrer"}},[_c('i',{staticClass:"fab fa-linkedin"}),_vm._v(" LinkedIn")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('a',{attrs:{"href":"https://github.com/craftycram/","target":"_blank","rel":"noopener noreferrer"}},[_c('i',{staticClass:"fab fa-github"}),_vm._v(" GitHub")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('a',{attrs:{"href":"https://discord.gg/fZ4jyPj","target":"_blank","rel":"noopener noreferrer"}},[_c('i',{staticClass:"fab fa-discord"}),_vm._v(" Discord")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('a',{attrs:{"href":"https://twitch.tv/craftycram/","target":"_blank","rel":"noopener noreferrer"}},[_c('i',{staticClass:"fab fa-twitch"}),_vm._v(" Twitch")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('a',{attrs:{"href":"https://www.instagram.com/marcrufeis/","target":"_blank","rel":"noopener noreferrer"}},[_c('i',{staticClass:"fab fa-instagram"}),_vm._v(" Instagram")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('a',{attrs:{"href":"https://twitter.com/craftycram/","target":"_blank","rel":"noopener noreferrer"}},[_c('i',{staticClass:"fab fa-twitter"}),_vm._v(" Twitter")])])
}]

export { render, staticRenderFns }